import styled from 'styled-components'

type IntersectionContainerProps = {
  isVisible: boolean
}

export const IntersectorNavigationBarContainer = styled.div<IntersectionContainerProps>`
  background-color: ${(props) => props.isVisible && '#f7f7f8'};
  z-index: ${(props) => props.isVisible && 2};
  position: ${(props) => props.isVisible && 'fixed'};
  top: ${(props) => props.isVisible && 0};
  right: ${(props) => props.isVisible && 0};
  left: ${(props) => props.isVisible && 0};
  width: ${(props) => props.isVisible && '100%'};
  flex-direction: ${(props) => props.isVisible && 'row'};
  display: ${(props) => props.isVisible && 'flex'};
`

export const NavigationBarContainer = styled.div<IntersectionContainerProps>`
  width: ${(props) => props.isVisible && '100%'};
`
