import { useEffect, useState, useRef } from 'react'
import { NavigationBar as AlliumNavigationBar, Spacer } from '@telus-uds/components-web'
import { SpacerProps } from '../Spacer/Spacer'
import useTriggerAnalyticsEvent from '../../../../hooks/useTriggerAnalyticsEvent'
import { renderSpacer } from 'src/siteBuilder/utils/renderSpacer'
import { ContentfulAnalyticsEventType } from 'src/siteBuilder/utils/types/analytics'
import { IntersectorNavigationBarContainer, NavigationBarContainer } from './Styles'

export type NavigationBarProps = {
  heading?: string
  headingLevel?: string
  items: NavigationBarItemProps[]
  selectedId: string
  spacer?: SpacerProps
  isNavigationSticky?: boolean
}

type NavigationBarItemProps = {
  id: string
  label: string
  href?: string
  analyticsEvent?: ContentfulAnalyticsEventType
  items?: NavigationBarSubItemProps[]
  onClick?: () => void
}

type NavigationBarSubItemProps = {
  id: string
  label: string
  href: string
  analyticsEvent?: ContentfulAnalyticsEventType
  onClick?: () => void
}

const NavigationBar = (props: NavigationBarProps) => {
  const { heading, headingLevel, items, selectedId, spacer, isNavigationSticky = false } = props

  const [isAnalyticsTriggered, setIsAnalyticsTriggered] = useState(false)
  const [analytics, setAnalytics] = useState<NavigationBarSubItemProps['analyticsEvent']>(null)
  const [isInView, setIsInView] = useState(true)
  const navBarRef = useRef<HTMLDivElement>(null)

  useTriggerAnalyticsEvent(analytics, isAnalyticsTriggered)

  useEffect(() => setIsAnalyticsTriggered(true), [analytics])

  useEffect(() => {
    items?.forEach((item) => {
      item.onClick = () => setAnalytics(item.analyticsEvent)

      item.items?.forEach((subItem) => (subItem.onClick = () => setAnalytics(subItem.analyticsEvent)))
    })
  }, [])

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsInView(!entry.isIntersecting)
      },
      { threshold: 1 }
    )

    if (navBarRef.current) {
      observer.observe(navBarRef.current)
    }
  }, [])

  return (
    <>
      <div data-testid="navigation-bar-testid" ref={navBarRef}>
        {renderSpacer(spacer)}
        <AlliumNavigationBar heading={heading} headingLevel={headingLevel} items={items} selectedId={selectedId} />
      </div>

      {isInView && isNavigationSticky && (
        <IntersectorNavigationBarContainer isVisible={isInView} data-testid="sticky-navigation-bar-testid">
          <Spacer space={{ xs: 3, lg: 11 }} direction="row" />
          <NavigationBarContainer isVisible={isInView}>
            {renderSpacer(spacer)}
            <AlliumNavigationBar heading={heading} headingLevel={headingLevel} items={items} selectedId={selectedId} />
          </NavigationBarContainer>
          <Spacer space={{ xs: 3, lg: 11 }} direction="row" />
        </IntersectorNavigationBarContainer>
      )}
    </>
  )
}

export default NavigationBar
